<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="إضافة عمل">
          <b-row>
            <b-col md="3" xl="3">
              <b-form-group label-for="delimiter">
                <label> العمل</label>
                <b-form-input v-model="workName" id="input-default" />
              </b-form-group>
            </b-col>
            <b-col md="3" xl="3">
              <b-form-group label-for="gender">
                <label> الجنس</label>
                <v-select
                  v-model="gender"
                  :options="optionGender"
                  :reduce="(val) => val.value"
                />
              </b-form-group>
            </b-col>
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="addwork">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> إضافة العمل</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-card-code title=" الأعمال">
      <!-- search input -->
      <!-- <div class="custom-search d-flex justify-content-start">
        <b-form-group>
          <div class="">
           
            <div>
              <b-form-input
                v-model="search"
                placeholder="بحث"
                type="text"
                class="d-inline-block"
              />
            </div>
          </div>
        </b-form-group>
      </div> -->
      <b-row>
      <b-col md="3" xl="3">
      <b-form-group label-for="gender">
       
                <label> الجنس</label>
                <v-select
                  v-model="genderFilter"
                  :options="optionGender"
                  :reduce="(val) => val.value"
                />
              </b-form-group>
            </b-col>
          </b-row>
      <!-- table -->
      <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 row">
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>

        </b-col>

        <b-col
          cols="6"
          md="6"
          class=""
        >
          <label> {{ total }}      عدد الأعمال</label>
          <label />

        </b-col>

      </div>

      <b-table
      ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <template

          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
          <b-dropdown-item-button
                  @click="
                    $router.push({
                      name: 'edit-document',
                      params: { id: data.item.id },
                    })
                  "
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>عرض</span>
                </b-dropdown-item-button>
                
                <b-dropdown-item-button @click="deleteDocument(data.item.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>
          </b-dropdown>
        </template>

         
            </b-table>

          

       
           </b-card>
           <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card-code>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { useToast } from "vue-toastification/composition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import useList from "./List";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
     BPagination,
  BTable,
  BCardHeader, 
  BCardBody,
} from "bootstrap-vue";
export default {
  deletWork(id) {
    this.$swal({
      icon: "warning",
      title: "هل انت متأكد من الحذف",
      confirmButtonText: "موافق",
      cancelButtonText: "الغاء",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "لا",
      },
    }).then((result) => {
      if (result.value) {
        let url = `/api/v1/work_types/${id}`;
        this.$http.delete(url).then((res) => {
          //console.log(res);
          this.getJobs();
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "تم الحذف  بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            },
            {
              position: "top-center",
            }
          );
        });
      }
    });
  },

  name: "",

  components: {
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
    BPagination,
  BTable,
  BCardHeader, 
  BCardBody,

  },
setup(){
  const {
    genderFilter,
    search,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      fetchData,
      

      // Extra Filters
    } = useList()
    
    return {
      fetchData,
      genderFilter,
      search,
      filterOn: [],
      searchTerm: "",
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
    }

},

  data() {
    return {
      workName: "",

      gender: "",

      columns: [
        {
          label: "العمل ",
          field: "name",
        },

        {
          label: "الخيارات",
          field: "id",
          sortable: true,
        },
      ],
      rows: [],
      searchTerm: "",
      id: "",
      optionGender: [],
    };
  },

  created() {
    // this.getJobs();
    this.getGender();
  },

  methods: {
    deleteWork(id) {
      this.$swal({
        icon: "warning",
        title: "هل انت متأكد من الحذف",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then((result) => {
        if (result.value) {
          let url = `/api/v1/work_types/${id}`;
          this.$http.delete(url).then((res) => {
            //console.log(res);
            // this.getJobs();
            this.refetchData();
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "تم الحذف  بنجاح",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          });
        }
      });
    },
    addwork() {
      let data = {
        name: this.workName,
        gender: this.gender,
      };
      this.$http.post("/api/v1/work_types", data).then((res) => {
        //console.log(res);
        this.workName=''
        this.gender=''
        // this.getJobs()
        this.refetchData();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "تم إضافة العمل بنجاح",
            icon: "CheckIcon",
              variant: "success",
            },
          },
          {
            position: "top-center",
          });
      });
    },
    getGender() {
      this.$http.get("/api/v1/constants/genders").then((res) => {
        //console.log(res);
        let gender = res.data;
        gender.forEach((el) => {
          this.optionGender.push({ value: el.id, label: el.name });
        });
        //console.log(this.optionGender);
      });
    },

    getJobs() {
      this.$http.get("/api/v1/work_types").then((res) => {
        this.rows = res.data.data;
      });
    },

    //
  },
};
</script> 

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>